import { Rating } from '@/components/rating'
import { Button } from '@/components/ui/button'
import { formatDate } from '@/lib/utils'
import { Credit } from '@/types'

const POSTER_URL = `https://www.themoviedb.org/t/p/w300_and_h450_bestv2`

export function Results({ shows }: { shows: Credit[] }) {
  return (
    <div className='flex flex-col gap-8 transition-opacity	'>
      {shows.map(c => {
        const name = c.original_name || c.original_title

        return (
          <div
            key={c.id}
            className='flex items-start gap-4 md:gap-8 text-card-foreground'
          >
            {c.poster_path && (
              <img
                src={`${POSTER_URL}/${c.poster_path}`}
                loading='lazy'
                alt={name}
                className='h-full rounded-lg object-cover  w-[100px] md:w-[200px]'
              />
            )}
            <div className='flex flex-col items-start text-left gap-4 '>
              <Button variant='link' className='p-0'>
                <a
                  href={`https://wheretowat.ch/?q=${name}`}
                  target='_blank'
                  rel='noopener'
                >
                  <h2 className='text-xl md:text-2xl md:mt-6 font-bold leading-none tracking-tight text-wrap text-left'>
                    {name}
                  </h2>
                </a>
              </Button>
              <small className='text-sm text-muted-foreground'>
                {formatDate(c.first_air_date || c.release_date)}
              </small>
              <Rating rating={c.vote_average / 2} />
              <p className='text-sm hidden md:block '>{c.overview}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
