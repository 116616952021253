import '@/components/loading.css'
export function Loading() {
  return (
    <div className='Loading'>
      <div className='container'>
        <div className='yellow'></div>
        <div className='red'></div>
        <div className='blue'></div>
        <div className='violet'></div>
      </div>
    </div>
  )
}

export default Loading
