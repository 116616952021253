import { Actor, Credit } from '@/types'

function getUniqueListBy<T, K extends keyof T>(arr: T[], key: K): T[] {
  return [...new Map(arr.map((item: T) => [item[key], item])).values()]
}

const getPerson = async (id: string) => {
  const response = await fetch(`/api?q=${id}&t=person`)
  const results = await response.json()
  return results
}

const searchPeople = async (query: string) => {
  const response = await fetch(`/api?q=${query}&t=search`)
  const results = await response.json()
  return results
}
const getCredits = async (id: string) => {
  const response = await fetch(`/api?q=${id}&t=credits`)
  const results = await response.json()
  return results
}

const getSharedCredits = (actors: Actor[]) => {
  if (actors.length < 2) return []

  const numberOfActors = actors.length
  const mixedCredits = actors.flatMap(a => getUniqueListBy(a.credits, 'id'))

  const findDuplicateIds = (array: Credit[]) => {
    const duplicateCreditIds = []

    for (const credit of array) {
      const count = array.filter(
        ({ id, media_type }) =>
          id === credit.id && media_type === credit.media_type,
      ).length
      if (count === numberOfActors) {
        duplicateCreditIds.push(credit.id)
      }
    }
    return duplicateCreditIds
  }

  const matchingCredits = findDuplicateIds(mixedCredits)
  const allCredits = mixedCredits.filter(c => matchingCredits.includes(c.id))

  return getUniqueListBy(
    allCredits.filter(
      c => !c.character.toLowerCase().includes('self') && c.character !== '',
    ),
    'id',
  ).sort(
    (a, b) =>
      new Date(b.first_air_date || b.release_date).getTime() -
      new Date(a.first_air_date || a.release_date).getTime(),
  )
}

export { getCredits, getPerson, getSharedCredits, searchPeople }
