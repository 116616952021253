import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import { Actor } from '@/types'
import { debounce } from 'lodash-es'
import { CircleX } from 'lucide-react'
import { useMemo, useState } from 'react'
import { searchPeople } from '../lib/api.ts'

const IMAGE_URL = `https://www.themoviedb.org/t/p/w180_and_h180_face`

type AutocompleteProps = {
  actors: Actor[] // Array of Actor objects
  setActors: React.Dispatch<React.SetStateAction<Actor[]>> // setState for an array of Actor
}

export function Autocomplete({ actors, setActors }: AutocompleteProps) {
  const [value, setValue] = useState('')
  const [filteredActors, setFilteredActors] = useState<Actor[]>([])
  const [searching, setSearching] = useState(false)

  const fetch = useMemo(
    () =>
      debounce(async ({ input }, callback) => {
        if (input.length) {
          const results: Actor[] = await searchPeople(input)
          callback(
            results
              ?.sort((a, b) => b.popularity - a.popularity)
              .filter(r => r.profile_path)
              .slice(0, 5),
          )
        }
      }, 400),
    [],
  )

  const selectActor = (actor: Actor) => {
    setActors([...actors, actor])
  }

  const removeActor = (actor: Actor) => {
    setActors([...actors].filter(a => a.id !== actor.id))
  }

  const searchActors = (v: string) => {
    if (v === '') {
      setFilteredActors([])
      setSearching(false)
    } else {
      setSearching(true)
      fetch({ input: v }, (results: Actor[]) => {
        setFilteredActors(results)
        setSearching(false)
      })
    }
  }

  return (
    <div>
      <div className='flex -space-x-12 rtl:space-x-reverse h-20 md:h-32 justify-center'>
        {actors.map(a => (
          <div className='relative avatar-hover' key={a.id}>
            <img
              className='w-20 h-20 md:w-32 md:h-32 border-2 border-white rounded-full dark:border-gray-800 shadow-sm'
              src={`${IMAGE_URL}/${a.profile_path}`}
              alt=''
            />
            <span
              role='button'
              tabIndex={0}
              aria-label='Remove actor'
              onKeyDown={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault()
                  removeActor(a)
                }
              }}
              className='x-button w-6 h-6  absolute top-4 left-16 md:left-24 transform -translate-y-1/2 rounded-full'
              onClick={() => removeActor(a)}
            >
              <CircleX style={{ fill: '#f87171' }} />
            </span>
          </div>
        ))}
      </div>

      <Command>
        <CommandInput
          loading={searching}
          placeholder={
            actors.length < 1 ? 'Search actors...' : 'Keep searching...'
          }
          value={value}
          onValueChange={v => {
            setValue(v)
            searchActors(v)
          }}
        />
        <CommandList>
          {!searching && value && filteredActors.length === 0 && (
            <CommandEmpty>No actors found.</CommandEmpty>
          )}
          <CommandGroup>
            {filteredActors.map(item => (
              <CommandItem
                key={item.id}
                value={item.id}
                onSelect={() => {
                  selectActor(item)
                  setValue('')
                  setFilteredActors([])
                }}
              >
                {item.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  )
}

// function ActorBadge({ actor }: { actor: Actor }) {
//   return (
//     <div className='flex gap-1 items-center'>
//       <Avatar className='h-6 w-6'>
//         <AvatarImage
//           alt={actor.name}
//           src={`${IMAGE_URL}/${actor.profile_path}`}
//         />
//       </Avatar>

//       {actor.name}
//     </div>
//   )
// }

// const insertBadgeOnSearch = (actor: Actor) => {
//   const wrapperElement = document.querySelector('[cmdk-input-wrapper]')
//   if (wrapperElement) {
//     // Find the <svg> element inside it
//     const svgElement = wrapperElement.querySelector('svg')
//     if (svgElement) {
//       // Create a new DOM node to host the React component
//       const newElement = document.createElement('div')

//       // Insert the new DOM node right after the <svg> element
//       svgElement.parentNode?.insertBefore(newElement, svgElement)

//       // Use React 18's createRoot to render the component
//       const root = createRoot(newElement)
//       // root.render(<YourInsertedComponent />)
//       root.render(
//         <Badge
//           key={actor.id}
//           variant='outline'
//           style={{ width: 'max-content', marginRight: '8px' }}
//         >
//           <ActorBadge actor={actor} />
//         </Badge>,
//       )
//     }
//   }
// }
