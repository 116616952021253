import { Button } from '@/components/ui/button'
import { MessageSquareShare } from 'lucide-react'
import { toast } from 'sonner'

export function Share() {
  return (
    <Button
      variant='ghost'
      size='icon'
      onClickCapture={() => {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => {
            toast(<div>URL Copied to clipboard ~ 🤗 Thanks!</div>)
          })
          .catch(err => {
            console.error('Failed to copy: ', err)
          })
      }}
    >
      <MessageSquareShare className='h-[1.2rem] w-[1.2rem] transition-all ' />
    </Button>
  )
}
