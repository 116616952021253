import { Autocomplete } from '@/components/autocomplete'
import { Footer } from '@/components/footer'
import { Loading } from '@/components/loading'
import { ModeToggle } from '@/components/mode-toggle'
import { Results } from '@/components/results'
import { Share } from '@/components/share'
import { ThemeProvider } from '@/components/theme-provider'
import { getCredits, getPerson, getSharedCredits } from '@/lib/api'
import { Actor, Credit } from '@/types'
import { useEffect, useState } from 'react'

function App() {
  const [actors, setActors] = useState<Actor[]>([])
  const [loading, setLoading] = useState(false)
  const [shows, setShows] = useState<Credit[]>([])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const actorsOnLoad = searchParams.get('q')?.split(',')
    const fetch = async (actorsOnLoad: string[]) => {
      const res = []
      for (const id of actorsOnLoad) {
        const p = await getPerson(id)
        res.push(p)
      }
      setActors(res)
    }

    if (actorsOnLoad && actorsOnLoad?.length > 0) {
      fetch(actorsOnLoad)
    }
  }, [])

  const updateQueryParam = (value: string[]) => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set('q', value.join(','))
    window.history.pushState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?${queryParams}`),
    )
  }

  const clearActors = () => {
    window.history.replaceState({}, '', window.location.pathname)
    setActors([])
    setShows([])
  }

  useEffect(() => {
    if (actors.length > 0) {
      updateQueryParam(actors.map(({ id }) => id))
    }
  }, [actors])

  useEffect(() => {
    if (actors?.length === 0) {
      window.history.replaceState({}, '', window.location.pathname)
      setShows([])
    }

    const creditsByActor: Actor[] = []
    const setCreditDataUp = async (creditsByA: Actor[]) => {
      setLoading(true)
      const creds: Credit[] = getSharedCredits(creditsByA)
      if (creds.length > 0) {
        setLoading(false)
        setShows(creds)
      } else {
        setShows([])
        setLoading(false)
      }
    }
    const getShows = async (match: Actor) => {
      setLoading(true)
      const result = await getCredits(match.id)
      creditsByActor.push(result)

      if (creditsByActor.length > 1) {
        await setCreditDataUp(creditsByActor)
      } else {
        setShows([])
      }
    }

    for (const match of actors) {
      const alreadyExists = creditsByActor.some(m => m.id === match.id)
      if (!alreadyExists) {
        getShows(match)
      }
    }
  }, [actors])

  const showResults = actors.length > 1
  return (
    <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
      <main>
        <div className='px-8'>
          <div className='flex justify-between items-center'>
            <h1 className='title cursor-pointer	' onClick={() => clearActors()}>
              CoStarred
            </h1>

            <nav className='flex items-center'>
              <Share />
              <ModeToggle />
            </nav>
          </div>

          <div className='max-w-[800px] m-auto'>
            <div className='flex flex-col gap-8 mt-20 text-center'>
              <h2 className='text-2xl md:text-4xl font-bold text-card-foreground '>
                Find your favourite actors and see what films they've appeared
                in together
              </h2>
              <p className='prose text-sm md:text-base text-muted-foreground'>
                Enter as many actors as you'd like to search their credits and
                see what project's they've worked on together, and where you can
                stream them
              </p>
            </div>
            <div className='flex flex-col gap-8 mt-8'>
              <Autocomplete actors={actors} setActors={setActors} />
              {showResults && loading && <Loading />}
              <Results shows={shows} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </ThemeProvider>
  )
}

export default App
