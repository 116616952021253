import { clsx, type ClassValue } from 'clsx'
import { format, isValid } from 'date-fns'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(date: string) {
  if (isValid(new Date(date))) {
    return format(new Date(date), 'PPP')
  } else {
    return date
  }
}
