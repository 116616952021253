import { Star } from 'lucide-react'

interface RatingProps {
  rating: number
}

export function Rating({ rating }: RatingProps) {
  const fullStars: number = Math.floor(rating)
  const partialStar: number = rating % 1
  const emptyStars: number = 5 - Math.ceil(rating)

  return (
    <div className='flex items-center'>
      {[...Array(fullStars)].map((_, i) => (
        <Star
          key={`full-${i}`}
          className='w-5 h-5 text-yellow-500 fill-current'
        />
      ))}
      {partialStar > 0 && (
        <div className='relative w-5 h-5'>
          <Star className='w-5 h-5 text-gray-300 fill-current absolute' />
          <div
            className='overflow-hidden absolute'
            style={{ width: `${partialStar * 100}%` }}
          >
            <Star className='w-5 h-5 text-yellow-500 fill-current' />
          </div>
        </div>
      )}
      {[...Array(emptyStars)].map((_, i) => (
        <Star
          key={`empty-${i}`}
          className='w-5 h-5 text-gray-300 fill-current'
        />
      ))}
    </div>
  )
}
